.home-page-container {
    .special-hero {
        height: 100vh;
        text-align: center;
        padding: 15vh 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
            font-size: 42px;
            background: unset;
            color: var(--color-main);
            // background: linear-gradient(to right, #ffc600, #ffc600);
            background-clip: unset;
            // background-clip: text;
            text-shadow: -2px 0 6px white, 0 2px 6px white, 2px 0 6px white, 0 -2px 6px white;
            // text-shadow: 2px 2px 2px white;
            // text-shadow: 0 0 10px #94770d57;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: unset;
        }

        p {
            width: 80%;
            line-height: 1.4;
            margin: 40px auto;
            max-width: 900px;
        }

        .button {
            display: inline-block;
            padding: 10px 20px;
            margin: 0 10px;
            border-radius: 100px;
        }

        .imgs {
            display: flex;
            gap: 15px;
            overflow: hidden;
            justify-content: center;
            margin-top: 30px;

            img {
                width: 50%;
                // height: 50vh;
                object-fit: cover;
                border-radius: 40px;
            }
        }

    }

    .why-us {
        padding: 200px 80px;
    }
}

@media screen and (max-width: 430px) {
    .home-page-container {
        .special-hero {
            height: auto;

            h1 {
                font-size: 50px;
            }

            p {
                font-size: 16px;
            }

            .imgs {
                margin-top: 40px;

                img {
                    width: 65%;
                    height: 200px;
                }
            }
        }

        .why-us {
            padding: 120px 30px;
        }
    }
}