.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 1000000000;
  transition: max-height 0.25s ease-in-out, height 0.25s ease-in-out,
    background-color 0.25s ease-in-out;

  &.background .expand-list a {
    color: black;
  }

  .header-list {
    display: flex;
    position: relative;
    top: 0;
    right: 0;
    opacity: 1;
    height: auto;
    max-height: auto;
    white-space: nowrap;
    gap: 30px;
    padding-right: 50px;
    color: var(--color-main);
    // font-weight: 600;
    // text-shadow: 0 -1px white, -1px 0 white, 1px 0 white, 0 1px white;

    // font-weight: 400;

    &.dark {
      color: var(--color-secondary-dark);
    }

    &.light {
      color: white;
    }

    .header-item {
      position: relative;

      &:hover .sub-header-list {
        @apply flex flex-col gap-3;
      }

      &.selected {
        > a {
          text-decoration: underline;
          text-decoration-thickness: 1.5px;
          text-underline-offset: 5px;
        }
      }

      .sub-header-list {
        display: none;
        position: absolute;
        top: 24px;
        left: 0%;
        background-color: white;
        padding: 12px;
        border-radius: 10px;
        z-index: 99999999;
      }
    }
  }

  &.small-screen {
    flex-direction: column;
    height: 0;
    max-height: 0vh;
    align-items: flex-end;

    .header-list {
      list-style: none;
      display: flex;
      gap: 32px;
      flex-direction: column;
      height: 0;
      width: 100vw;
      position: absolute;
      top: 75px;
      right: 0%;
      opacity: 0;
      padding-right: 25px;
      background-color: var(--color-secondary-dark);
      // background-color: white;
      transition: height 0.25s ease-out, opacity 0.25s ease-in-out;
      overflow: hidden;

      .header-item {
        font-weight: 400;
        text-align: center;
        color: var(--color-main);

        &:first-of-type {
          margin-top: 60px;
        }

        .sub-header-list {
          display: none;
          position: relative;
          // top: 24px;
          // left: 0%;
          margin-top: -20px;
          margin-bottom: 20px;
          background-color: transparent;
          padding: 12px;
          border-radius: 0px;
          z-index: 99999999;

          &.open {
            @apply flex flex-col text-white gap-4;
          }
        }
      }
    }

    &.background.close {
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      background-color: rgba(0, 0, 0, 0.4);
      // background-color: rgba(256, 256, 256, 0.5);
      max-height: 70px;
      height: 70px;
    }

    &.close {
      transition-delay: 0.15s;
      background-color: transparent;
      max-height: 0;
      height: 0;

      .header-list {
        height: 0;
        opacity: 0;
        transition-delay: 0s;
      }
    }

    &.open {
      transition-delay: 0s;
      background-color: var(--color-secondary-dark);
      // background-color: white;
      max-height: 100vh;
      height: 100vh;

      .header-list {
        height: calc(100vh - 75px);
        opacity: 1;
        transition-delay: 0.25s;
      }
    }
  }

  &.background {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
    // background-color: rgba(256, 256, 256, 0.5);
    max-height: 70px;
    height: 70px;

    .header-list.dark {
      color: white;
    }
  }

  > div {
    width: 100%;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .menu-icon {
      color: var(--color-main);

      &:hover {
        cursor: pointer;
      }
    }
  }

  img {
    height: 40px;

    &:hover {
      cursor: pointer;
    }
  }
}

html.noscroll {
  overflow: hidden !important;
}

body.noscroll {
  overflow: visible !important;
  height: 100%;
}
