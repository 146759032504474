.add-listing-page-container {

    .form {
        max-width: 600px;
        margin-bottom: 150px;
        @apply mt-14;

        h2 {
            @apply font-semibold text-2xl text-gray-700 mb-3;
        }

        label {
            @apply mb-1 flex justify-between;
        }

        label span {
            @apply mr-4;
        }

        input {
            @apply input input-bordered w-full max-w-xs input-sm;
        }
    }

    .section {
        @apply flex flex-col mb-12;
    }
}