.footer-container {
    border-top: solid #ffc8006f 0.5px;
    display: flex;
    flex-direction: column;
    padding: 50px;
    gap: 50px;
    font-size: 15px;

    img {
        height: 40px;
        width: auto;
        object-fit: contain;
        align-self: flex-start;
    }

    >div {
        display: flex;
        justify-content: space-between;

        li {
            margin-bottom: 12px;
        }
    }

    .copyright {
        gap: 60px;

        >div.text {
            opacity: 0.7;
        }

        >div.logo {
            display: flex;
            white-space: nowrap;
            align-self: flex-end;
            align-items: center;
            gap: 3px;
            font-weight: 600;

            p {
                opacity: 1;
                margin-right: 10px;
            }

            img {
                background-color: var(--color-secondary-light);
                padding: 4px;
                width: 80px;
                border-radius: 2px;
            }
        }

        p {
            // text-align: center;
            font-size: 12px;
            opacity: 0.6;
            margin-top: 5px;
        }
    }
}

@media screen and (max-width: 900px) {
    .footer-container {


        .copyright {
            margin-top: 60px;
            gap: 30px;
            flex-direction: column;

            >div.logo {
                order: 1;
            }

            >div.text {
                order: 2;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .footer-container {
        padding: 100px 30px 30px;

        >div {
            flex-direction: column;
            gap: 60px;
            // text-align: right;
        }

        .copyright {
            gap: 10px;

            >div.logo {
                flex-direction: column;
                gap: 5px;

                img {
                    align-self: flex-end;
                    margin-right: 10px;

                    &:first-of-type {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}