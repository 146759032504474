/* POLICY CSS */
.policy-container {
    padding: 160px 60px 150px;

    h1 {
        color: var(--color-main);
        margin: 40px 0 60px 0;
    }

    h2 {
        margin-bottom: 20px;
    }

    h3 {}

    ul {
        list-style: none;

    }

    >ul {
        >li {
            margin: 50px 0;

            >ul {
                margin: 20px 0;

                >li {
                    margin: 10px 0;
                }
            }
        }
    }


    p,
    li {
        line-height: 1.5;
    }

    a {
        color: var(--color-main);
        text-decoration: none;
    }
}

@media screen and (max-width: 430px) {
    .policy-container {
        padding: 80px 30px 60px;
    }
}