.stampDutyPage {
    padding-bottom: 240px;

    @apply text-gray-700;

    p {
        @apply text-base font-main-sans;
    }

    h2 {
        @apply mt-12 mb-2;
    }

    .specialH2 {

        @apply mt-2;
    }

    h3 {
        @apply mt-12 mb-3 text-2xl font-semibold text-[#ffc600];
        foo: bar;
        // @apply mt-8 mb-2 text-2xl font-semibold text-[#FFAA00];
        // @apply mt-8 mb-2 text-2xl font-semibold opacity-80 underline underline-offset-2;
        // text-decoration-color: var(--color-main);
        // text-decoration-thickness: 1px;
        // text-shadow: 1px 1px 0px var(--color-main);
    }

    h4 {
        @apply mt-6 underline;
    }

    table {
        @apply text-base mb-6;

        tr {
            border-bottom-color: rgba(165, 165, 165, 0.1) !important;
        }
    }
}