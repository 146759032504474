.hero {
  position: relative;
  place-items: unset;

  h1 {
    // color: var(--color-main);
  }

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  p {
    max-width: 900px;
    margin: 0 auto;

    &.intro {
      margin: 10vh auto 50px;
      line-height: 1.5;
      opacity: 0.9;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.452);
    }

    &.learn-more {
      margin-bottom: 20px;
    }

  }

  .expand-icon {
    span {
      font-size: 28px;
      font-weight: 100;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .background {
    >div {
      background-color: rgba(27, 27, 27, 0.773);
      width: 100%;
      height: 100vh;
      text-align: center;
      position: relative;
      z-index: 100;
      padding: 25vh 80px 0;
      display: flex;
      flex-direction: column;
    }
  }

  &.full-page {
    .expand-icon {
      margin-top: 20px;

      span {
        font-size: 60px;
        font-weight: 100;
      }
    }
  }

  &.half-page {
    .background {
      // margin-left: 30px;
      width: 46%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .hero {
    &.half-page {
      .background {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .hero {
    &.half-page {
      .background {
        width: 80%;
      }
    }
  }
}


@media screen and (max-width: 650px) {
  .hero {
    img {
      filter: brightness(0.4)
    }

    .background {
      // padding: 15vh 30px 10vh;
      padding: 0;
      display: flex;
      align-items: center;
      height: 100vh;

      >div {
        gap: 30px;
        padding: 0 30px;
        height: auto;
        background-color: transparent;
      }

      p {
        font-size: 18px;

        &.intro {
          margin: 0px 0;
        }

        &.learn-more {
          margin-bottom: 10px;
        }
      }
    }

    &.half-page {
      .background {
        justify-content: unset;
        gap: 60px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .hero {

    .background {
      // padding-top: 240px;
      // padding-top: 120px;

      // overflow-y: scroll;
      >div {
        padding-top: 120px;
        padding-bottom: 120px;
        height: 100%;
        overflow-y: scroll;

      }
    }
  }
}