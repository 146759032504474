@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  font-size: 16px;
  --color-main: #ffc600;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  /* color: rgb(var(--foreground-rgb)); */
  @apply bg-secondary-light;
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@layer base {
  h1 {
    @apply font-main-serif italic inline-block text-main mx-auto my-0 font-semibold mb-8 text-4xl;
  }

  h2 {
    @apply font-main-serif mb-10 font-[12px] sm:font-[34px];
    /* font-size: 34px; */
  }

  h3 {
    @apply font-main-serif;
    font-size: 22px;
  }

  p {
    @apply font-main-serif;
    font-size: 1.2rem;
  }
}

@layer utilities {
  .content-inner {
    padding-top: 180px !important;
    max-width: 900px;
  }
  @media screen and (max-width: 640px) {
    .content-inner {
      padding-top: 120px !important;
      max-width: 900px;
    }
  }
  /* .grid2 {
    margin-top: 100px;
    display: grid;
    row-gap: 80px;
    column-gap: 60px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;

    h3 {
      font-weight: 400;
    }

    p {
      margin-top: 10px;
      opacity: 0.8;
      font-weight: 300;
    }
  } */

  .nav {
    text-align: end;
    padding: 50px;

    a {
      color: var(--color-secondary-dark);
      text-decoration: none;
    }

    span {
      opacity: 0.3;
      font-weight: 300;
    }

    .selected {
      font-weight: 700;
      color: var(--color-main) !important;
    }
  }

  .block-inner {
    padding: 150px 80px;

    &.guides {
      p {
        line-height: 1.5;

        a {
          text-decoration-thickness: 1px;
          text-underline-offset: 2px;
          text-decoration-color: #ffc800;
        }
      }
    }

    .clGMain {
      background: linear-gradient(to right, #ffc600, #5b4a0b);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .hideSpinner {
    /* For Webkit browsers like Chrome, Safari */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* For Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .ptHeader {
    padding-top: 75px;
  }

  @media screen and (max-width: 900px) {
    .grid2 {
      grid-template-columns: 1fr;
      row-gap: 50px;
    }
  }

  @media screen and (max-width: 430px) {
    .nav {
      padding: 30px;
      text-align: center;
    }
  }
}

ul {
  list-style: none;
}

a {
  color: inherit;
}

.isEnglish .english {
  display: block;
}

.isEnglish .chinese {
  display: none;
}
.isChinese .english {
  display: none;
}

.isChinese .chinese {
  display: block;
}
