.about-us-page-container {
    .section {
        .content {
            padding: 120px 80px 200px;

            h2 {
                display: inline-block;
                margin-bottom: 80px;
            }

            p {
                line-height: 1.5;
                width: 70%;
                margin: 0 auto;
            }
        }

        &.vision {
            text-align: center;

            .nav {

                a,
                span {
                    color: var(--color-secondary-dark);
                }
            }
        }

        &.mission {
            text-align: center;

            .nav {

                a,
                span {
                    color: var(--color-secondary-light);
                }
            }
        }

        &.values {
            text-align: center;

            .nav {

                a,
                span {
                    color: var(--color-secondary-dark);
                }
            }

            h2 {
                margin-bottom: 140px;
            }

            .values-container {
                display: grid;
                grid-template-columns: repeat(3, 28%);
                text-align: start;
                justify-content: space-between;
                row-gap: 120px;

                .value:last-of-type {
                    grid-column: 2/3;
                }

                h3 {
                    font-weight: 600;
                }

                p {
                    margin: 0;
                    margin-top: 10px;
                    width: 100%;
                    opacity: 0.9;
                }
            }
        }
    }

    .legal {
        padding: 24px;
        max-width: 1200px;
        font-size: 10px;
        margin: 0 auto;
        color: #a19d93;
    }
}

@media screen and (max-width: 800px) {
    .about-us-page-container {
        .section {
            .content {
                p {
                    width: 90%;
                }
            }

            &.values {
                h2 {
                    margin-bottom: 100px;
                }

                .values-container {
                    grid-template-columns: repeat(2, 48%);
                    column-gap: 30px;

                    .value:last-of-type {
                        grid-column: 1/3;
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 650px) {
    .about-us-page-container {
        .section {
            .content {
                padding: 60px 30px 120px;

            }

            &.values {
                .values-container {
                    display: flex;
                    flex-direction: column;
                    gap: 50px;
                }
            }

        }
    }
}