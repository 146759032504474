.qualifications-page-container {


    .qualification {
        width: 80vw;
        padding: 120px 80px 200px 80px;

        img {
            width: 250px;
            margin-bottom: 60px;
        }

        p {
            line-height: 1.5;
            margin-top: 20px;

            a {
                color: var(--color-main);
                text-decoration: none;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .qualifications-page-container {
        .qualification {
            width: 100%;
        }
    }
}

@media screen and (max-width: 430px) {
    .qualifications-page-container {
        .qualification {
            padding: 60px 30px 120px;

            img {
                margin-bottom: 30px;
            }
        }
    }
}