.careers-page-container {
    .content {
        padding: 200px 80px;

        h2 {
            margin-top: 100px;
        }

        h3 {
            margin: 0 0 30px;
        }

        h4 {
            margin: 50px 0 10px;
        }

        h4:first-of-type {
            margin-top: 0;
        }

        ul {
            list-style: decimal;
            margin-left: 20px;

            li {
                margin: 8px 0;
            }
        }

        a {
            color: var(--color-main);
            text-decoration: none;
        }
    }
}

@media screen and (max-width: 430px) {
    .careers-page-container {
        .hero {
            .background {
                gap: 30px;
            }
        }

        .content {
            padding: 30px 30px 120px;
        }
    }
}