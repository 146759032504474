.landlords-page-container {
    .price-list {
        p {
            margin-bottom: 16px;

            a {
                text-decoration-color: #ffc800;

            }
        }
    }

    .services-container {




        >div.content {
            padding: 100px 80px 200px;

            h2 {
                margin-bottom: 20px;
            }

            .sub {
                font-size: 26px;
                font-family: var(--font-serif);
                font-weight: 500;
                margin-left: 50px;
            }

            .services {
                margin-top: 100px;
            }
        }

        &.pre {
            .nav {
                color: var(--color-secondary-dark);

                .selected {
                    color: var(--color-main);
                }
            }
        }

        &.during {
            .nav {

                a,
                span {
                    color: var(--color-secondary-light);
                }

                .selected {
                    color: var(--color-main);
                }
            }

            .services {
                .service:first-child {
                    grid-column: 1/-1;
                }

                h3 {
                    color: var(--color-main);
                }
            }
        }
    }


}

@media screen and (max-width: 430px) {
    .landlords-page-container {
        .services-container {
            >div.content {
                padding: 40px 30px 120px;

                .services {
                    margin-top: 50px;
                }
            }
        }

        .price-list {
            padding: 120px 30px;
        }
    }
}