.banner-cookie-container {
    // background-color: var(--light-blue);
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    max-height: calc(100vh - 40px);
    width: 440px;
    z-index: 100000000000000000;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    color: black;
    font-weight: 400;
    overflow: scroll;


    p {
        line-height: 1.2;
    }

    a {
        color: inherit;
    }

    h2 {
        margin-bottom: 20px;
        font-weight: 600;
    }

    h3 {
        font-weight: 500;
    }

    .buttons {
        display: flex;
        gap: 16px;
        margin: 20px 0 0;
        align-items: center;

        button {
            padding: 10px 15px;
            border: none;
            border-radius: 8px;
            font-weight: 600;
            font-size: 14px;
            background-color: rgba(0, 0, 0, 0.05);
            display: flex;
            align-items: center;
            gap: 5px;

            >span {
                font-size: 14px;
                font-weight: 700;
            }

            &:first-of-type {
                background-color: var(--color-main);
                color: white;
            }

            &:last-of-type {
                border: none;
                padding: 2px 0px;
                border-bottom: 1.8px black solid;
                border-radius: 0px;
                background-color: transparent;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

    .manage-cookies-container {
        height: 0;
        display: none;

        &.open {
            height: auto;
            display: block;
            transition: height 2s ease-in-out;
        }

        ul.manage-cookies {
            margin: 30px 0;
            list-style: none;

            li {
                margin: 20px 0;
                padding-top: 20px;
                border-top: 0.5px solid #2c384f29;

                >div {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 12px;
                    align-items: center;
                }
            }
        }

        button.save {
            width: 100%;
            background-color: var(--color-main);
            padding: 10px;
            color: white;
            border-radius: 10px;
            font-weight: 600;
            font-size: 14px;
            border: none;

            &:hover {
                cursor: pointer;
            }
        }
    }


    /* Hide the scrollbar for webkit browsers (Chrome, Safari, etc.) */
    &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
        display: none;
    }

    /* Optional: Style the track (the area behind the thumb) */
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /* Optional: Style the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 0.25em;
    }

    /* Optional: Style the scrollbar on hover */
    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
}

.toggle {
    .layer {
        width: 58px;
        height: 32px;
        border-radius: 50px;
        background-color: rgba(0, 0, 0, 0.066);
        position: relative;

        &:hover {
            cursor: pointer;
        }

        &.no {
            // opacity: 0.5;
        }

        &.yes {
            background-color: var(--color-main);
            opacity: 1;

            .knob {
                right: 6px;
                left: unset;
            }
        }

        .knob {
            position: absolute;
            height: 22px;
            width: 22px;
            left: 5px;
            right: unset;
            top: 5px;
            border-radius: 50px;
            background-color: white;
        }


    }

}

@media screen and (max-width: 430px) {

    .banner-cookie-container {
        width: 100vw;
        left: 0;
        bottom: 0px;
        max-height: calc(100vh - 150px);

    }

}