.properties-container {
    padding: 200px 80px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;

    .properties {
        max-width: 1800px;
        display: grid;
        grid-template-columns: repeat(3, calc((100% - 15px) / 3));
        justify-content: space-between;
        gap: 15px;
    }

    h2 {
        // align-self: flex-start;
        max-width: 900px;
        margin: 0 0 120px 0;
    }

    &.bg-dark {
        h2 {
            color: var(--color-main);
        }

        .property-container {
            border: 1px solid var(--color-main);

        }
    }

    &.bg-light,
    &.bg-white {
        h2 {
            color: var(--color-secondary-dark);
        }

        .property-container {
            background-color: white;
            border: 0.5px solid var(--color-main);
        }


    }

    .button {
        background-color: var(--color-main);
        padding: 10px 40px;
        border-radius: 100px;
        font-family: var(--font-serif);
        font-size: 20px;
        font-weight: 600;
        color: var(--color-secondary-dark);
        margin: 60px 0 100px;
    }
}

.property-container {
    background-color: var(--color-secondary-light);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 8px;
    border-radius: 15px;
    max-width: 900px;
    display: grid;
    grid-template-columns: 40% 1fr;
    gap: 0px;
    text-decoration: none;

    &:hover {
        cursor: default;
    }

    &.link:hover {
        cursor: pointer;
    }

    .img {
        position: relative;
        width: 100%;

        img {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            min-height: 200px;
            object-fit: cover;
        }

        .tag {
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 5px 10px;
            border-radius: 5px;
            font-weight: 600;
            font-size: 15px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            background-color: var(--color-secondary-light);
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        width: 100%;

        .name {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 10px;
        }

        .location {
            font-weight: 200;
        }

        .features {
            display: flex;
            gap: 10px;
            // align-self: flex-start;

            .feature {
                display: flex;
                align-items: center;
                gap: 3px;

                &:nth-of-type(2) {
                    // transform: translateY(2px);
                }
            }


        }

        .bottom {
            align-items: flex-end;
            width: 100%;
            // flex-direction: column;
            justify-content: space-between;
        }

        .price {
            font-weight: 700;
            font-size: 18px;

            span {
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 1770px) {
    .properties-container {
        .properties {
            max-width: 1300px;
            grid-template-columns: repeat(2, calc((100% - 15px) / 2));
        }
    }
}

@media screen and (max-width: 1260px) {
    .properties-container {
        .properties {
            max-width: 800px;
            grid-template-columns: repeat(1, calc((100% - 15px) / 1));
        }
    }
}

@media screen and (max-width: 900px) {
    .property-container {
        .properties {
            grid-template-columns: repeat(2, calc((100% - 15px) / 2));
        }

        .text {
            .bottom {
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .properties-container {
        padding: 120px 30px;

        h2 {
            margin-bottom: 60px;
        }

        .button {
            margin-bottom: 0px;
        }
    }

    .property-container {
        flex-direction: column;
        gap: 0;

        .img {
            width: 100%;

            img {}
        }

        .text {
            .bottom {
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
            }

            .features {
                margin-top: 10px;
                gap: 10px;
            }
        }
    }
}