* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  --color-main: #ffc600;
  --color-secondary-dark: #393939;
  --color-secondary-light: #f4f2ed;
  // --color-secondary-light: #faf9f6;

  --font-serif: "Times New Roman", Times, serif;
  --font-sans-serif: "Helvetica Neue", sans-serif;

  --size-h1: 44px;
  --size-h2: 34px;
  --size-h3: 22px;
  --size-p: 18px;
}

h1 {
  font-family: var(--font-serif);
  font-size: var(--size-h1);
  font-style: italic;
  font-size: var(--size-h1);
  display: inline-block;
  color: var(--color-main);
  // background: linear-gradient(to right, #ffc600, #5b4a0b);
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  margin: 0 auto;
}

h2 {
  font-family: var(--font-serif);
  margin-bottom: 40px;
  font-size: var(--size-h2);
}

h3 {
  font-family: var(--font-serif);
  font-size: var(--size-h3);
}

p {
  font-size: var(--size-p);
}

ul {
  list-style: none;
}

a {
  color: inherit;
}

.bg-dark {
  background-color: var(--color-secondary-dark);
}

.bg-white {
  background-color: white;
}

.bg-light {
  background-color: var(--color-secondary-light);
}

.bg-main {
  background-color: var(--color-main);
}

.cl-main {
  color: var(--color-main);
}

.cl-light {
  color: var(--color-secondary-light);
}

.cl-dark {
  color: var(--color-secondary-dark);
}

.cl-white {
  color: white;
}

/* gradient color-main */
.cl-g-main {
  background: linear-gradient(to right, #ffc600, #5b4a0b);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.fs-i {
  font-style: italic;
}

.a-no-decor,
.a-no-decor a {
  text-decoration: none;
}

.grid-2 {
  margin-top: 100px;
  display: grid;
  row-gap: 80px;
  column-gap: 60px;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;

  h3 {
    font-weight: 400;
  }

  p {
    margin-top: 10px;
    opacity: 0.8;
    font-weight: 300;
  }
}

.nav {
  text-align: end;
  padding: 50px;

  a {
    color: var(--color-secondary-dark);
    text-decoration: none;
  }

  span {
    opacity: 0.3;
    font-weight: 300;
  }

  .selected {
    font-weight: 700;
    color: var(--color-main) !important;
  }
}

.flex {
  display: flex;
}

.block {
  padding: 150px 80px;

  &.guides {
    p {
      line-height: 1.5;

      a {
        text-decoration-thickness: 1px;
        text-underline-offset: 2px;
        text-decoration-color: #ffc800;
      }
    }
  }
}

.p-t-header {
  padding-top: 75px;
}

.hide-spinner {
  /* For Webkit browsers like Chrome, Safari */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
  }
}

@media screen and (max-width: 900px) {
  .grid-2 {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
}

@media screen and (max-width: 430px) {
  .nav {
    padding: 30px;
    text-align: center;
  }
}
