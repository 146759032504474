.rent-page-container {
    .renting-process {
        h2 {
            margin: 0;
            padding: 200px 80px 100px;
            text-align: center;
        }

        .item {
            padding: 0 150px;
            display: flex;
            gap: 150px;
            // align-items: center;
            color: var(--color-secondary-dark);

            >div {
                padding: 120px 0;
            }

            img {
                height: 250px;
                opacity: 0.7;
                align-self: center;
            }

            h3 {
                font-size: 32px;
                margin-bottom: 30px;
            }

            &:first-of-type {
                >div {
                    padding: 80px 0;
                }

                img {
                    align-self: flex-end;
                    width: 30vw;
                    height: auto;
                }
            }
        }

        .begin {
            padding: 200px 80px;
            text-align: center;

            p {
                width: 60%;
                margin: 0 auto;
                font-family: var(--font-serif);
                font-size: 24px;
                font-weight: 600;

            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .rent-page-container .renting-process .item {
        gap: 30px;
    }
}

@media screen and (max-width: 800px) {
    .rent-page-container {
        .renting-process {
            h2 {
                padding: 120px 60px 100px;
                color: var(--color-main);
            }

            .item {
                padding: 0 60px;
                flex-direction: column;
                gap: 30px;

                >div {
                    padding: 100px 0 0;
                }

                img {
                    width: 60%;
                    order: 1;
                }

                &:first-of-type {
                    >div {
                        padding: 20px 0;
                    }

                    img {
                        width: 60%;
                    }
                }

                &:nth-of-type(2) {
                    img {
                        align-self: flex-start;
                    }
                }

                &:nth-of-type(3) {
                    img {
                        align-self: flex-end;
                    }
                }

                &:nth-of-type(4) {
                    img {
                        align-self: flex-start;
                    }
                }
            }

            .begin {
                padding: 120px 30px;

                p {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .rent-page-container {
        .renting-process {
            h2 {
                padding: 120px 30px 100px;
            }

            .item {
                padding: 0 30px;
            }

            .begin {
                padding: 120px 30px;


            }
        }
    }
}