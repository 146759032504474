.rentalYieldContainer {
    @apply sm:pb-[240px] pb-[120px] text-secondary-dark;
    foo: bar;

    .tableContainer {


        table {
            @apply pb-8;
            border: 1px solid rgba(227, 146, 66, 0.2);
            background-color: rgba(255, 255, 255, 0.5);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
            width: 100%;

            td,
            th {
                @apply py-2 px-2 sm:px-5;
            }

            td:first-child {
                @apply sm:pr-[120px];
                foo: bar;
            }

            td:last-child {
                white-space: nowrap;
            }

            .span {
                opacity: 0.4;
                font-weight: 400;
            }

            input {
                background-color: transparent;
            }
        }
    }
}