.contact-us-page-container {
    .content {
        padding: 250px 80px;
        display: flex;
        flex-direction: column;
        gap: 200px;

        .flex {
            display: flex;
            gap: 100px;
        }

        a {
            color: var(--color-main);
            text-decoration: none;
        }

        p {
            line-height: 1.5;
        }
    }
}

@media screen and (max-width: 800px) {
    .contact-us-page-container {
        .content {
            gap: 100px;

            .flex {
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .contact-us-page-container {
        .content {
            padding: 120px 30px;
        }
    }
}